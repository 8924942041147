import { css } from 'styled-components';

export const breakpoints = {
    xs: '480px',
    sm: '768px',
    md: '992px',
    lg: '1024px',
    xl: '1100px',
    xlarge: 'Infinity',
};

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
    accumulator[label] = (...args) =>
        css`
            @media (min-width: ${breakpoints[label]}) {
                ${css(...args)};
            }
        `;
    return accumulator;
}, {});

const theme = {
    primary: '#fff',
    secondary: '#000',
    text: '#212121',
    inputtext: '#BDBDBD',
    brand: '#FF5C00',
    border: '#9F9F9F',
    error: '#cc0000',
    background: '#F3F3F3',
    buttonGrey: '#F1F1F1',
    gradient: 'linear-gradient(180deg, #FF4D00 0%, #FF7A00 100%)',
    fontSizes: ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '48px', '64px'],
    fontWeights: [300, 400, 500, 600, 700],
    borderRadius: {
        input: '5px',
        button: '60px',
        form: '10px',
    },
    space: ['8px', '16px', '24px', '32px', '40px', '48px', '64px', '96px', '110px'],
    ...media,
};
export default theme;
import styled from 'styled-components';
import { StyledParagraph } from './typography';



export const StyledImg = styled.img`
  padding-right: 10px;
`;

export const StyledCenterDivColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledCenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCenterDevRight = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding-left: 40px;
`;

export const StyledSpacedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.primary};
  border-color: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  padding: ${({ theme }) => theme.space[0]};
  color: ${({ theme }) => theme.text};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  width: 100%;
  height: ${({ theme }) => theme.space[4]};
  box-sizing: border-box;
`;


export const StyledInputMargin = styled.input`
  background-color: ${({ theme }) => theme.primary};
  border-color: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  padding: ${({ theme }) => theme.space[0]};
  color: ${({ theme }) => theme.text};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  width: 100%;
  height: ${({ theme }) => theme.space[4]};
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.space[0]};
  ${({ theme }) => theme.sm`
    margin-bottom: 0;
  `}`;

export const StyledButton = styled.button`
  background: ${({ theme }) => theme.gradient};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  color: ${({ theme }) => theme.primary};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  min-width: 200px;
  box-sizing: border-box;
  height: 40px;
  outline: none;
  cursor: pointer;
  border: none;
  margin-top: ${({ theme }) => theme.space[0]};
  &.active {
      background: ${({ theme }) => theme.primary};
      border: 2px solid ${({ theme }) => theme.brand};
      color: ${({ theme }) => theme.text};
  }
  &.selected {
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.text};
      border: 2px solid ${({ theme }) => theme.brand};
  }
  &:hover {
    background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.text};
      border: 2px solid ${({ theme }) => theme.brand};
  }
`;

export const StyledCheckbox = styled.input`
  width: ${({ theme }) => theme.space[1]};
  height: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.primary};
  border-color: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  box-sizing: border-box;
  position: relative;
`;

export const StyledSelect = styled.select`
  background-color: ${({ theme }) => theme.primary};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  padding: ${({ theme }) => theme.space[0]};
  color: ${({ theme }) => theme.text};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  width: 100%;
  height: ${({ theme }) => theme.space[4]};
  box-sizing: border-box;
`;

export const StyledTextarea = styled.textarea`
  background-color: ${({ theme }) => theme.primary};
  border-color: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  padding: ${({ theme }) => theme.space[0]};
  color: ${({ theme }) => theme.text};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  resize: none;
`;

export const ErrorMessage = styled.p`
  position: absolute;
  padding-top: 0;
  color: ${({ theme }) => theme.error};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export const ErrorMessageCheckbox = styled.p`
  position: absolute;
  top: 56px;
  color: ${({ theme }) => theme.error};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export const Wrapper = styled.main`
  max-width: 650px;
  margin: 40px auto;
`;

export const ModalLink = styled.span`
  color: ${({ theme }) => theme.brand};
  &:hover {
    color: ${({ theme }) => theme.inputtext};
  }
`

export const FormContent = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};
`
export const ActionBtnContainer = styled.div`
  width: 100%;
  height: 60px;
  margin-top: ${({ theme }) => theme.space[3]};
`
export const ActionBtnLeft = styled.button`
  background: ${({ theme }) => theme.buttonGrey};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  color: ${({ theme }) => theme.text};
  border: ${props => (props.revertColor ? '2px solid orange' : 'none' )};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  box-sizing: border-box;
  display: inline-block;
  outline: none;
  cursor: pointer;
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.form};
  height: 100%;
  width: 50%;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`
export const ActionBtnRight = styled.button`
  background: ${({ theme }) => theme.gradient};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  color: ${({ theme }) => theme.primary};
  border: ${props => (props.revertColor ? '2px solid orange' : 'none' )};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  box-sizing: border-box;
  display: inline-block;
  outline: none;
  cursor: pointer;
  border-radius: 0 0 ${({ theme }) => theme.borderRadius.form} 0;
  height: 100%;
  width: 50%;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.sm`
     flex-direction: row;
  `}
`
export const FlexContainerInline = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  ${({ theme }) => theme.sm`
     align-items: center;
  `}
  input {
    margin-top: ${({ theme }) => theme.space[3]};
    margin-right: ${({ theme }) => theme.space[0]};
  }
`

export const FlexChild = styled.div`
  width: 100%;
  ${({ theme }) => theme.sm`
     width: 50%;
     &:first-of-type {
      margin-right: ${({ theme }) => theme.space[0]};
    }
    &:last-of-type {
      margin-left: ${({ theme }) => theme.space[0]};
    }
  `}
`
export const FlexChildOne = styled.div`
  width: 100%;
  ${({ theme }) => theme.sm`
    width: 50%;
    &:first-of-type {
      margin-right: ${({ theme }) => theme.space[2]};
    }
  `}
`
export const ServerError = styled(StyledParagraph)`
  padding-bottom: ${({ theme }) => theme.space[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: 1.5;
  margin: 0;
`

export const ConfirmationWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.space[2]};
`

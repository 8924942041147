import React from 'react';
import { StyledH2, StyledH3, StyledParagraph } from '../styles/typography';
import { FormContent } from '../styles/global';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
`
const Content = styled.div`
  position:fixed;
  background: ${({ theme }) => theme.primary};
  width: 90%;
  max-width: 650px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  margin: 0 10px;
  ${({ theme }) => theme.sm`
     margin: 0 auto;
  `} 
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 2px rgba(19, 63, 63, 0.25);
  padding-bottom: ${({ theme }) => theme.space[2]};
`

const ParagraphModal = styled(StyledParagraph)`
  margin-bottom: ${({ theme }) => theme.space[1]};
`

const CloseIcon = styled.img`

  background: none;
  border: none;
  outline: none;
  width: ${({ theme }) => theme.space[1]};
  height: auto;
`

export const FixedAgreementHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;
const ErrorComponent = ({ open, setOpen }) => {
  return (
    <Wrapper>
      <Content>
        <FormContent>
          <FixedAgreementHeader>
          <StyledH2>Allmänna villkor Kunder</StyledH2>
          <StyledH3>Gäller från 2021-04-11</StyledH3>
          <CloseIcon alt="close icon" src="/Close.png" onClick={() => setOpen(!open)}></CloseIcon>
          </FixedAgreementHeader>
          
          <StyledH3>§ 24 DUTYS ANSVAR</StyledH3>
          <ParagraphModal>24 Duty är en förmedlingstjänst av hantverkare. Alla kostnader som uppkommer i samband med uppdraget tillfalla kunden. Om 24 Dutys hantverkare inte kan ta uppdraget inom gällande tidsperiod (40 minuter) kommer 24 Duty meddela kunden att uppdraget ej kan förmedlas och därmed får kunden inga kostnader för beställningen. 24 Duty garanterar inte 100% tillgänglighet och kan inte hållas ansvarig om tjänsten inte kan levereras.
          </ParagraphModal>
          <StyledH3>§ HANTVERKARENS ANSVAR</StyledH3>
          <ParagraphModal>Hantverkaren ansvarar för att tjänsten utförs på ett korrekt sätt och innehar de behörigheter som krävs för uppdraget. All kontakt gälande uppdraget sker direkt mellan hantverkare och kunden. Hantverkaren har därmed ansvaret för framtida reklamationer och garantier.</ParagraphModal>
          <StyledH3>§ KUNDENS ANSVAR</StyledH3>
          <ParagraphModal>All kommunikation skall i första hand ske med hantverkaren direkt, både under och efter att arbetet är utfört. Vid fel skall kunden så fort det är möjligt informera hantverkaren så att felet går att åtgärda så snabbt som möjligt. Eventuella merkostnader som kan uppkomma i samband med reklamation ersätts inte av 24 Duty. Om kunden inte vill att hantverkaren slutför/tilläggsarbetet på uppdraget har kunden rätt att tacka nej till detta men då kommer hantverkaren enbart utföra det som ingår i tjänsten för den delen av uppdraget som berör utryckningen (grundpriset) och därefter avsluta jobbet. Då har kunden i efterhand rätt att anlita någon annan som utför återstående. Grundpriset kommer då debiteras kunden. Vid godkännande av avslutat uppdrag intygar kunden att all information från användaravtalet har lästs och godkänts.</ParagraphModal>
          <StyledH3>{`§ PRISER & VAD SOM INGÅR`}</StyledH3>
          <ParagraphModal>
            Privatperson (inkl. moms)

            Grundpriset är 4000 SEK (20 dagar)

            Grundpris med ROT 2830 SEK (20 dagar)

            Grundpris med RUT 2050 SEK (20 dagar)
          </ParagraphModal>
          <ParagraphModal>
            Det ingår: 2 timmar arbetstid på plats samt framkörning av servicebil.

            Det ingår inte: Material och extratid, extratid debiteras med 1000kr/timma.
          </ParagraphModal>
          <ParagraphModal>
            Om kund ej angivit uppgifter för skattereduktion vid beställning kan detta läggas till när hantverkaren är på plats om detta inte görs kan kunden inte i efterhand ändra till skattereduktion utan att 24 Duty tar ut en administrativ kostnad om 300 kr för detta. Väljer kunden skattereduktion men det visar sig i efterhand att kunden inte är berättigad det av Skatteverket har 24 Duty rätt att skicka faktura på motsvarande belopp samt servicekostnad om 300kr.
          </ParagraphModal>
          <ParagraphModal>
          Företag (exkl. moms)

Grundpriset är 3200 SEK (30 dagar)
          </ParagraphModal>
          <ParagraphModal>
          Det ingår: 2 timmar arbetstid på plats samt framkörning av servicebil.

Det ingår inte: Material och extratid, extratid debiteras med 800kr/timma.
          </ParagraphModal>
          <StyledH3>
          {`§ TILLÄMPNING LAG & TVIST`}
          </StyledH3>
          <ParagraphModal>
          Tvist i anledning av uppdraget skall avgöras av Göteborgs tingsrätt med tillämpning av svensk lag.
          </ParagraphModal>
          <StyledH3>
          § ÅNGERRÄTT
          </StyledH3>
          <ParagraphModal>
          Kunden har efter det att beställningen gjorts ingen ångerrätt men blir inte debiterad mer än grundpriset om kunden väljer att avbryta uppdraget.
          </ParagraphModal>
          <StyledH3>
          § KREDITUPPLYSNING
          </StyledH3>
          <ParagraphModal>
          24 Duty har rätten att ta sedvanlig kreditupplysning för att använda tjänsten. Kreditupplysningen påverkar inte andra krediter utan används för adressinhämtning samt kreditvärdighet.
          </ParagraphModal>


          {/* <StyledH3>4. 24 Dutys ansvar</StyledH3>
          <ParagraphModal>Tjänsten tillhandahåller uppdrag som skapats av kunden själv eller tillsammans med 24 Dutys agenter över telefon med kunden. Info som anges baserad på vad kunden uppgivit. 24 Duty kontaktar sina hantverkare via sitt system i realtid direkt efter att detta avtal har signerats och inte innan. Om 24 Dutys hantverkare inte kan ta uppdraget inom gällande tidsperiod kommer 24 Duty meddela kunden att uppdraget ej kan förmedlas och därmed skall detta avtal ej gälla och kunden får inga kostnader för beställningen. 24 Duty blir heller inte ersättningsskyldiga för att inte ha kunnat erbjuda tjänsten. 24 Duty ansvarar för att tillhandahålla kopia av detta avtal. Vill kunden i efterhand ha en kopia på avtalet skall kunden själv kontakta 24 Duty via hemsidan och fylla i formuläret för avtalskopior.</ParagraphModal>
          <StyledH3>5. Hantverkarens ansvar</StyledH3>
          <ParagraphModal>I en del situationer är det nödvändigt för oss att anlita andra parter. Till exempel olika IT-leverantörer för utveckling av vårt system samt leverantörer för kreditupplysning samt finansbolag. De är då personuppgiftsbiträden till oss. Vi kontrollerar personuppgiftsbiträden för att säkerställa att de garanterar säkerheten och sekretessen för personuppgifterna. När personuppgiftsbiträden anlitas sker det bara för de ändamål som är förenliga med de ändamål vi själva har för behandlingen.</ParagraphModal>
          <StyledH3>6.Kundens ansvar</StyledH3>
          <ParagraphModal> Vi delar även dina personuppgifter med vissa andra aktörersom är självständigt personuppgiftsansvariga, till exempel myndigheter som Skatteverket, när vi är skyldiga att lämna ut sådana uppgifter med stöd av lag eller myndighetsbeslut. När dina personuppgifter delas med en aktörsom är självständigt personuppgiftsansvarig gäller den organisationens integritetspolicy och personuppgiftshantering.</ParagraphModal>
          <StyledH3>7. Kostnader och vad ingår</StyledH3>
          <ParagraphModal> Det ingår 2 timmar arbetstid samt framkörning av servicebil. Priset är 2800kr efter ROT-avdrag för grundpriset samt arbetstiden men har kunden ej angivit fastighetsbeteckning vid beställning kan ingen ROT dras och ordinarie pris på 4000kr inkl moms gäller. Väljer kunden ROT men det visar sig i efterhand att kunden inte är berättigad ROT av skatteverket har 24 Duty rätt att skicka faktura på motsvarande belopp samt servicekostand om 300kr. Kunden har fram tills att hantverkaren lämnar platsen och kunden signerar avtalet att bestämma sig att betala med eller utan ROT. Efter signeringen går detta inte att ändra. I de fall då ordinarie pris (grundpriset) inte räcker för att klara av uppdraget kommer eventuellt extraarbete och material debiteras utöver grundkostnaden mot kunden. Detta kommer kommuniceras på plats av hantverkaren och kunden har rätt att tacka nej till detta men då kommer hantverkaren enbart utföra det som ingår i tjänsten för jour och därefter avsluta jobbet. Då har kunden i efterhand rätt att anlita någon annan som utför återstående.</ParagraphModal>
          <StyledH3>8. Force Majeur</StyledH3>
          <ParagraphModal>24 Duty är befriad från påföljd för underlåtenhet att fullgöra förpliktelse enligt avtalet, om underlåtenheten har sin grund i omständighet av det slag som anges nedan ("befriande omständighet") och omständigheten förhindrar, försvårar eller försenar fullgörandet därav. Såsom befriande omständighet ska anses bl.a. myndighets åtgärd eller underlåtenhet, nytillkommen eller ändrad lagstiftning, konflikt på arbetsmarknaden, blockad, brand, översvämning, knapphet på transportmedel, varor eller energi eller olyckshändelse av större omfattning samt fel i eller försening av leverans från underleverantör orsakad av händelse utanför parts kontroll.</ParagraphModal>
          <StyledH3>9. Tillämplig lag och tvist</StyledH3>
          <ParagraphModal>Tvist i anledning av Avtalet skall avgöras av Göteborgs tingsrätt med tillämpning av svensk lag.</ParagraphModal>
          <StyledH3>10. Ångerrätt</StyledH3>
          <ParagraphModal>Kunden har efter det att beställningen gjorts ingen ångerrätt utan full jourkostnad kommer att tas ut oavsett om kunden avbeställer tjänsten till hantverkaren eller 24 Duty innan jobbet påbörjats.</ParagraphModal>
          <StyledH3>11. Godkännande</StyledH3>
          <ParagraphModal>Här med godkännes att kund beställt uppdraget av 24 Duty och 24 Duty har anlitat hantverkare från sitt nätverk för att utföra uppdraget. Alla kostnader som uppkommer i samband med jouruppdraget tillfaller kunden.</ParagraphModal>
          <StyledH3>12. Integritetspolicy</StyledH3>
          <ParagraphModal>För 24 Duty AB är personlig integritet viktigt. Vi eftersträvar en hög nivå av dataskydd. För att läsa mer om vår integritetspolicy och hur vi hanterar dina personuppgifter. Besök www.24duty.se. begära rättelse av felaktiga uppgifter och i vissa fall radering.</ParagraphModal> */}
        </FormContent>
      </Content>
    </Wrapper>
  )
}

export default ErrorComponent
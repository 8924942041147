import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form';
import CircleLoader from '../CircleLoader';
import Card from '../Card';
import ButtonGroup from '../ButtonGroup';
import ErrorComponent from '../ErrorComponent';
import HeadingSection from '../HeadingSection';
import { StyledH3 } from '../../styles/typography';
import { 
  StyledInput,
  ErrorMessage,
  FormContent,
  ActionBtnRight,
  ActionBtnLeft,
  ActionBtnContainer } from '../../styles/global';

const url = require('./config.json')

const Part2 = ({ globalState, setGlobalState, goForward, goBack }) => {
  const { register, handleSubmit, errors } = useForm({});
  const {
    register: registerOrg,
    handleSubmit: handleSubmitOrg,
    errors: errorsOrg
  } = useForm({});
  const [serverError, setServerError] = useState('');
  const [isCompany, setIsCompany] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    window.dataLayer.push({event:'conversionStep2'});
  }, [])

 /*  useEffect(() => {
    const a = document;
    const d = 'script';
    const r = "https://t.adii.io/convert.js";
    const e = a.createElement(d);
    e.innerText = `!(function (a, d, r, e, c) { adrecord = {"programID": 1325, "orderID": "testorder1", "couponCode": ""}; e = a.createElement(d); e.async=!0; e.src=r; c=a.getElementsByTagName(d)[0]; c.parentNode.insertBefore(e, c);})(document,"script","https://t.adii.io/convert.js");`
    const c = a.getElementsByTagName(d)[0]
    c.parentNode.insertBefore(e, c);
  }, []) */

  const onSubmit = async (formData) => {

    setIsLoading(true);
    const apiClient = axios.create({
      baseURL: url.baseURL,
      responseType: 'json',
    })
    const t = (globalState.Part1 && globalState.Part1.title) ? globalState.Part1.title : "Ingen titel"
    let body = {...formData, isCompany, title: t}
    
    try {
      const response = await apiClient.post(
        '/customers/creditCheck', body
      )
      const data = response.data
      if (!data.status) {
        setIsLoading(false);
        setServerError('Tyvärr blev du inte godkänd i kreditkollen.')
        return
      }

      window.dataLayer.push({event:'isCompany', data: isCompany});

      if (data.address) {
        let name = {
          firstName: data.firstName,
          lastName: data.lastName
        }
        let address = {
          address: data.address,
          streetNo: data.streetNo,
          zipCode: data.zipCode,
          city: data.city,
        }
        let companyName = isCompany ? data.companyName : ""
        let ssid = "hej";
        setGlobalState({...globalState, Part2: formData, isCompany, address, Part3: {...address, ...name, companyName, ssid}})
      } else {
        setGlobalState({...globalState, Part2: formData, isCompany })
      }
      setIsLoading(false);
      goForward()
    } catch(error) {
      setServerError('Någonting gick fel. Prova igen.')
      setIsLoading(false);
    }
  }

  const setCompany = (event) => {
    event.preventDefault();
    if (event.target.name === 'Privatperson') {
      setIsCompany(false)
      setShow(true)
      setGlobalState({...globalState, address: {}})
    }
    if (event.target.name === 'Företag') {
      setIsCompany(true)
      setShow(true)
      setGlobalState({...globalState, address: {}})
    }
  };
  return (
    <section>
      <Card>
      {serverError ? (
        <ErrorComponent serverError={serverError} />
      ) : (
        <>
        <FormContent>
          <HeadingSection 
            heading={'Är du privatperson eller företag?'}
          />
          <ButtonGroup 
            buttons={["Privatperson", "Företag"]}
            onClickAction={setCompany}
          />
        </FormContent>

        {show && !isCompany && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
              <StyledH3>Personnummer*</StyledH3>
              <StyledInput
                type="text"
                name="ssid"
                placeholder="YYMMDDXXXX"
                ref={register({
                  required: "Ange personnummer",
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Ange ett svenskt personnummer med 10 siffror ÅÅMMDDNNNN'
                  }
                })}
              />
              {errors.ssid ? <ErrorMessage>{errors.ssid.message}</ErrorMessage> : null}
            </FormContent>

            {isLoading ? 
              <CircleLoader />
            : 
              <ActionBtnContainer>
                <ActionBtnLeft onClick={() => goBack()}>Tillbaka</ActionBtnLeft>
                <ActionBtnRight type="submit">Gå vidare</ActionBtnRight>
              </ActionBtnContainer>
            }
          </form>
        )}

        {show && isCompany && (
          <form onSubmit={handleSubmitOrg(onSubmit)}>
            <FormContent>
               <StyledH3>Organisationsnummer*</StyledH3>
               <StyledInput
                type="text"
                name="ssid"
                ref={registerOrg({
                  required: "Ange organisationsnummer",
                  pattern: {
                    value: /^\d{6}[-]\d{4}$/,
                    message: 'Ange organisationsnummer med 10 siffror XXXXXX-XXXX'
                  }
                })}
              />
              {errorsOrg.ssid ? <ErrorMessage>{errorsOrg.ssid.message}</ErrorMessage> : null}
            </FormContent>
            {isLoading ? 
              <CircleLoader />
            : 
            <ActionBtnContainer>
              <ActionBtnLeft onClick={() => goBack()}>Tillbaka</ActionBtnLeft>
              <ActionBtnRight type="submit">Gå vidare</ActionBtnRight>
            </ActionBtnContainer>
            }
          </form>
        )}
        </>
      )}
      </Card>
    </section>
  )
};
export default Part2;
import React, { useState } from "react";
import styled from 'styled-components';

const StyledButton = styled.button`
  background: ${({ theme }) => theme.primary};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  color: ${({ theme }) => theme.text};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  min-width: 180px;
  box-sizing: border-box;
  height: 40px;
  outline: none;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.brand};
  margin-top: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[3]};
  &:first-of-type {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.sm`
    margin-bottom: ${({ theme }) => theme.space[3]};
  `}
  &.active {
      background: ${({ theme }) => theme.gradient};
      color: ${({ theme }) => theme.primary};
      border: none;
  }
  &.selected {
      background: ${({ theme }) => theme.gradient};
      color: ${({ theme }) => theme.primary};
      border: none;
  }
  &:hover {
    background: ${({ theme }) => theme.gradient};
      color: ${({ theme }) => theme.primary};
      border: none;
  }
`;

const ButtonGroup = ({ buttons, onClickAction}) => {
  const [clickedId, setClickedId] = useState(-1);
  const handleClick = (event, id) => {
    if(id === clickedId){
      setClickedId(null);
    }else{
      setClickedId(id);
    }
    onClickAction(event);
  };

  return (
    <> 
      {buttons.map((buttonLabel, i) => (
        <StyledButton
          key={i}
          name={buttonLabel}
          onClick={(event) => handleClick(event, i)}
          className={i === clickedId ? "active" : ""}
        >
          {buttonLabel}
        </StyledButton>
        )
      )}
    </>
  );
};

export default ButtonGroup;
import React from 'react';
import { StyledH2, StyledH3, StyledParagraph } from '../styles/typography';
import { FormContent } from '../styles/global';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
`
const Content = styled.div`
  position:fixed;
  background: ${({ theme }) => theme.primary};
  width: 90%;
  max-width: 650px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  margin: 0 10px;
  ${({ theme }) => theme.sm`
     margin: 0 auto;
  `} 
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 2px rgba(19, 63, 63, 0.25);
  padding-bottom: ${({ theme }) => theme.space[2]};
`

const ParagraphModal = styled(StyledParagraph)`
  margin-bottom: ${({ theme }) => theme.space[1]};
`

const CloseIcon = styled.img`

  background: none;
  border: none;
  outline: none;
  width: ${({ theme }) => theme.space[1]};
  height: auto;
`

export const FixedPolicyHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;

const ErrorComponent = ({open, setOpen}) => {
  return (
    <Wrapper>
      <Content>
        <FormContent>
          <FixedPolicyHeader>
          <StyledH2>Integritetspolicy (GDPR)</StyledH2>
          <CloseIcon alt="close icon" src="/Close.png" onClick={() => setOpen(!open)}></CloseIcon>
          </FixedPolicyHeader>
          <StyledH3>Version:01 Skapad: 2021-03-29</StyledH3>
          <StyledH3>Behandling av personuppgifter på 24 Duty AB556835-6652</StyledH3>
          <ParagraphModal>För 24 Duty AB är personlig integritet viktigt. Vi eftersträvaren hög nivå av dataskydd. I denna policy förklarar vi hur vi samlar in och använder personuppgifter. Vi beskriver också dina rättigheter och hur du kan göra dem gällande.
          </ParagraphModal>
          <ParagraphModal>Du är alltid välkommen att kontakta oss om du har frågor om hur vi behandlar dina personuppgifter. Kontaktuppgifter står sist i denna text.</ParagraphModal>
          <StyledH3>Vad är en personuppgift och vad är en behandling av personuppgifter?</StyledH3>
          <ParagraphModal>Personuppgifter är alla uppgifterom en levande fysisk person som direkt eller indirekt kan kopplas till den personen. Det handlar inte bara om namn och personnummer utan även om till exempel bilder och e-postadresser.</ParagraphModal>
          <ParagraphModal>Behandling av personuppgifter är allt som sker med personuppgifterna i IT-systemen, oavsett om det handlar om mobila enheter eller datorer. Det handlar om till exempel insamling, registrering, strukturering, lagring, bearbetning och överföring. I vissa fall kan även manuella register omfattas.</ParagraphModal>
          <StyledH3>Personuppgiftsansvarig</StyledH3>
          <ParagraphModal>För de behandlingar som sker inom 24 Dutysverksamhet är 24 Duty AB personuppgiftsansvarig. (556835-6652 Korsvägen4, 47542, Hönö).</ParagraphModal>
          <StyledH3>Vilka personuppgifter samlar vi in om dig och varför?</StyledH3>
          <ParagraphModal>Vi behandlar i huvudsak ditt namn, personnummer, adress och kontaktuppgifter, telefonnummer för att kunna tillhandahålla förmedling av jouruppdrag till vårt hantverkarnätverk.</ParagraphModal>
          <ParagraphModal>Vi behandlar dina personuppgifter för att kunnafullgöravåra skyldigheter enligt vårt beställningsavtal med dig och för att uppfylla övriga åtagande mot underleverantörer för sedvanlig kreditupplysning vid beställningstillfället.</ParagraphModal>
          <StyledH3>Personuppgiftsbiträden</StyledH3>
          <ParagraphModal>I en del situationer är det nödvändigt för oss att anlita andra parter. Till exempel olika IT-leverantörer för utveckling av vårt system samt leverantörer för kreditupplysning samt finansbolag. De är då personuppgiftsbiträden till oss. Vi kontrollerar personuppgiftsbiträden för att säkerställa att de garanterar säkerheten och sekretessen för personuppgifterna. När personuppgiftsbiträden anlitas sker det bara för de ändamål som är förenliga med de ändamål vi själva har för behandlingen.</ParagraphModal>
          <StyledH3>Aktörer som är självständigt personuppgiftsansvariga</StyledH3>
          <ParagraphModal> Vi delar även dina personuppgifter med vissa andra aktörersom är självständigt personuppgiftsansvariga, till exempel myndigheter som Skatteverket, när vi är skyldiga att lämna ut sådana uppgifter med stöd av lag eller myndighetsbeslut. När dina personuppgifter delas med en aktörsom är självständigt personuppgiftsansvarig gäller den organisationens integritetspolicy och personuppgiftshantering.</ParagraphModal>
          <StyledH3>Hur länge sparar vi dina personuppgifter?</StyledH3>
          <ParagraphModal>Vi sparar aldrig dina personuppgifter längre än vad som är nödvändigt för respektive ändamål. Vissa uppgifter i bokföringen behöver på grund av lagstiftning till exempelsparas minst sju år.</ParagraphModal>
          <StyledH3>Vad är dina rättigheter som registrerad?</StyledH3>
          <ParagraphModal>Som registrerad har du enligt gällande lagstiftning ett antal rättigheter. Du har rätt till att få ett utdrag som visar vilka personuppgifter vi har registrerade om dig. Du kan begära rättelse av felaktiga uppgifter och i vissa fall radering.</ParagraphModal>
          <StyledH3>Kontakta oss vid frågor om hur vi behandlar personuppgifter.</StyledH3>
          <ParagraphModal>Om du har frågor om hur vi behandlar personuppgifter kontakta 24 Dutys GDPR avdelningsom är ansvarig för personuppgiftsfrågor på mailadressen GDPR@24duty.se</ParagraphModal>
          <ParagraphModal>Vi kan komma att göra ändringar i vår integritetspolicy. Den senaste versionen av integritetspolicyn finns alltid här på webbplatsen.</ParagraphModal>
        </FormContent>
      </Content>
    </Wrapper>
  )
}
  
export default ErrorComponent
import React from 'react';
import styled from 'styled-components';

const ProgressStage = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.space[0]};
  background: ${props => (props.active || props.complete ? props.theme.gradient : '#c2c2c2')};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  outline: none;
  border: none;
  color: white;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const Checkmark = styled.img`
  width: ${({ theme }) => theme.space[1]};
  height: auto;
`;

const ProgressBar = ({ stage, step }) => {
  return (
    <>
    {step <= 6 && (
      <ProgressStage
        active={stage === 'active' ? true : false}
        complete={stage === 'complete' ? true : false}
        disabled={stage !== 'complete' ? true : false}
      > 
        {stage === 'complete' ? (
          <Checkmark alt="checkmark" src="/Vector.png" />
        ) : (
          <span>{step}</span>
        )}
      </ProgressStage>
    )}
    </>
  );
};

export default ProgressBar;
import React, { useState, useEffect } from "react";
import axios from "axios";
import CircleLoader from "../CircleLoader";
import Card from "../Card";
import { useForm } from "react-hook-form";
import ErrorComponent from "../ErrorComponent";
import HeadingSection from "../HeadingSection";
import { StyledH3, StyledParagraph } from "../../styles/typography";
import UserAgreemnetModal from "../UserAgreemnetModal";
import {
  FormContent,
  ActionBtnRight,
  ActionBtnLeft,
  ErrorMessage,
  ActionBtnContainer,
  StyledCheckbox,
  ErrorMessageCheckbox,
  FlexContainerInline,
  ModalLink,
  StyledCenterDiv,
  StyledSpacedDiv,
  StyledImg,
  StyledCenterDevRight,
} from "../../styles/global";
import { storeInLocalStorage } from "../../functions";
import timer from "../../assets/icons/timer.png";
import clock from "../../assets/icons/clock.png";
import { useNavigate } from "react-router-dom";

const url = require("./config.json");

const Part5 = ({
  globalState,
  setGlobalState,
  goBack,
  goForward,
  setCurrentStep,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm({});
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const navigate = useNavigate();

  const getStoredData = () => {
    setValue("description", globalState.Part1.description);
  };

  const goSend = () => {
    navigate("/tack");
  };

  const createContract = async (type) => {
    setIsLoading(true);
    const apiClient = axios.create({
      baseURL: url.baseURL,
      responseType: "json",
    });
    const body = {
      firstName: globalState.Part3.firstName,
      lastName: globalState.Part3.lastName,
      phone: globalState.Part3.phoneNo,
      type: type,
    };
    try {
      const response = await apiClient.post("/customers/createContract", body);
      const data = response;
      if (data.status !== 200) {
        setIsLoading(false);
        setServerError("Tyvärr kunde vi inte skapa ditt kontrakt");
      }
      let agreementDetails = data.data;
      setGlobalState({
        ...globalState,
        Confirmed: true,
        agreementDetails,
      });
      setIsLoading(false);
      goForward();
    } catch (error) {
      setIsLoading(false);
      setServerError("Tyvärr kunde vi inte skapa ditt kontrakt");
    }
  };

  useEffect(() => {
    window.dataLayer.push({event:'conversionStep4'});
  }, []);

  useEffect(() => {
    if (globalState === undefined) {
      setCurrentStep(0);
      return;
    }
  }, []);

  useEffect(() => {
    getStoredData();
  });

  const onSubmit = async (formInput) => {
    const Part1 = {
      description: formInput.description,
      privacyPolicy: globalState.Part1.privacyPolicy,
      profession: globalState.Part1.profession,
      title: globalState.Part1.title,
      zipCode: globalState.Part1.zipCode,
    };
    setGlobalState({ ...globalState, Part1 });
    storeInLocalStorage("stored", "Part1", Part1);
  };

  const nextStep = async () => {
    if (document.getElementById("check").checked) {
      setCheckError(false);
      goForward();
    } else {
      setCheckError(true);
    }
  };

  const filteredWork = globalState.professions.filter(
    (p) => Number(p.id) === Number(globalState.Part1.profession)
  );
  const workTitle =
    filteredWork.length > 0
      ? filteredWork[0].title
      : globalState.Part1.profession;

  console.log(globalState.Part1, filteredWork, workTitle);
  return (
    <>
      <section>
        <Card>
          {serverError ? (
            <ErrorComponent serverError={serverError} />
          ) : (
            <>
              <FormContent>
                <HeadingSection
                  heading={"Kontrollera dina uppgifter"}
                  paragraph={
                    "Kontrollera att dina uppgifter stämmer eller gå tillbaka och ändra."
                  }
                  paragraph2={
                    "I nästa steg efter godkännande av våra allmänna villkor skapas din order."
                  }
                />
                <StyledSpacedDiv>
                  <StyledCenterDiv>
                    <StyledCenterDiv>
                      <StyledImg src={timer} width="20" height="20" />
                      <StyledParagraph>Svar inom 1 min</StyledParagraph>
                    </StyledCenterDiv>
                    <StyledCenterDevRight>
                      <StyledImg src={clock} width="20" height="20" />
                      <StyledParagraph>
                        Hantverkare på plats inom 60-90 min
                      </StyledParagraph>
                    </StyledCenterDevRight>
                  </StyledCenterDiv>
                </StyledSpacedDiv>
              </FormContent>
              {globalState ? (
                <FormContent>
                  {globalState.Part1 ? (
                    <>
                      <StyledH3>Vad har hänt?</StyledH3>
                      <p>Rubrik: {globalState.Part1.title}</p>
                      <p>Yrkesgrupp: {workTitle}</p>
                      <p>Postnummer: {globalState.Part1.zipCode}</p>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Beskrivning:</p>
                        <p>{globalState.Part1.description}</p>
                        {errors.description ? (
                          <ErrorMessage>
                            {errors.description.message}
                          </ErrorMessage>
                        ) : null}
                      </form>
                    </>
                  ) : null}
                  {globalState.Part3 ? (
                    <>
                      <StyledH3>Faktureringsadress</StyledH3>
                      {globalState.Part3.companyName && (
                        <p>{globalState.Part3.companyName}</p>
                      )}
                      <p>
                        {globalState.Part3.firstName}{" "}
                        {globalState.Part3.lastName}
                      </p>
                      <p>
                        {globalState.Part3.address} {globalState.Part3.streetNo}
                      </p>
                      <p>
                        {globalState.Part3.zipCode} {globalState.Part3.city}
                      </p>
                      {globalState.Part3.contact && (
                        <p>Kontaktperson: {globalState.Part3.contact}</p>
                      )}
                      <p>{globalState.Part3.phoneNo}</p>
                      {globalState.Part3.email && (
                        <p>{globalState.Part3.email}</p>
                      )}

                      {globalState.isOneAddress && (
                        <>
                          <StyledH3>Adress där arbetet skall utföras</StyledH3>
                          <p>
                            {globalState.Part3.address}{" "}
                            {globalState.Part3.streetNo}
                          </p>
                          <p>
                            {globalState.Part3.zipCode} {globalState.Part3.city}
                          </p>
                          {globalState.Part3.doorCode && (
                            <p>Portkod: {globalState.Part3.doorCode}</p>
                          )}
                        </>
                      )}
                    </>
                  ) : null}

                  {globalState.Part3.buildingNumber && (
                    <>
                      <StyledH3>ROT-avdrag för villa</StyledH3>
                      <p>Fastighetskod: {globalState.Part3.buildingNumber}</p>
                    </>
                  )}

                  {globalState.Part3.buildingNumberAp && (
                    <>
                      <StyledH3>ROT-avdrag för lägenhet</StyledH3>
                      {globalState.Part3.buildingNumberAp && (
                        <p>
                          Lägenhetsnummer: {globalState.Part3.buildingNumberAp}
                        </p>
                      )}
                      {globalState.Part3.brfOrgnr && (
                        <p>Brf org.nummer: {globalState.Part3.brfOrgnr}</p>
                      )}
                    </>
                  )}

                  {globalState.otherJobAddress ? (
                    <>
                      <StyledH3>Adress där arbetet skall utföras</StyledH3>
                      <p>
                        {globalState.Part3.addressJob}{" "}
                        {globalState.Part3.streetNoJob}
                      </p>
                      <p>
                        {globalState.Part3.zipCodeJob}{" "}
                        {globalState.Part3.cityJob}
                      </p>
                      {globalState.Part3.doorCode && (
                        <p>Portkod: {globalState.Part3.doorCode}</p>
                      )}
                    </>
                  ) : null}
                </FormContent>
              ) : null}

              <FlexContainerInline style={{ marginLeft: 20 }}>
                <StyledCheckbox
                  type="checkbox"
                  id="check"
                  placeholder="privacyPolicy"
                  name="privacyPolicy"
                  ref={register({ required: "Godkänn för att gå vidare" })}
                />
                <StyledH3>
                  Jag godkänner 24dutys{" "}
                  <ModalLink onClick={() => setOpen(!open)}>
                    Användarvillkor
                  </ModalLink>
                  *
                </StyledH3>
                {checkError ? (
                  <ErrorMessageCheckbox>
                    {"Godkänn för att gå vidare"}
                  </ErrorMessageCheckbox>
                ) : null}
              </FlexContainerInline>

              {isLoading ? (
                <CircleLoader />
              ) : (
                <ActionBtnContainer>
                  <ActionBtnLeft onClick={() => goBack()}>
                    Tillbaka
                  </ActionBtnLeft>
                  <ActionBtnRight
                    onClick={() => goSend() /*createContract("touch-sign")*/}
                  >
                    Beställ
                  </ActionBtnRight>
                </ActionBtnContainer>
              )}
            </>
          )}
        </Card>
      </section>
      {open && <UserAgreemnetModal open={open} setOpen={setOpen} />}
    </>
  );
};
export default Part5;

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CircleLoader from '../CircleLoader';
import Card from '../Card';
import ButtonGroup from '../ButtonGroup';
import styled from 'styled-components';
import HeadingSection from '../HeadingSection';
import { StyledH2, StyledH3 } from '../../styles/typography';

import {
  StyledInput,
  ErrorMessage,
  FormContent,
  ActionBtnRight,
  ActionBtnLeft,
  ActionBtnContainer,
  FlexContainer,
  FlexChild,
  FlexChildOne
} from '../../styles/global';

const H2Margin = styled(StyledH2)`
  margin-bottom: 0;
`;

const Part3 = ({ globalState, setGlobalState, goForward, goBack }) => {
  const { register, handleSubmit, setValue, getValues, errors } = useForm({});
  const [isOneAddress, setIsOneAddress] = useState(null)
  const [otherJobAddress, setOtherJobAddress] = useState(null)
  const [isHouse, setIsHouse] = useState(false);
  const [isApartment, setIsApartment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showBuildingInfo, setShowInfo] = useState(false);
  const [showHouseInfo, setShowHouseInfo] = useState(false);
  const [showApInfo, setShowApInfo] = useState(false);


  const buildingNumberAp = getValues("buildingNumberAp");
  const brfOrgnr = getValues("brfOrgnr");

  const getStoredData = () => {
    let stored = globalState
    if (!stored) {
      return null
    }
    if (stored.Part3) {
      [{ name: 'address', value: stored.Part3.address },
      { name: 'addressJob', value: stored.Part3.addressJob },
      { name: 'city', value: stored.Part3.city },
      { name: 'cityJob', value: stored.Part3.cityJob },
      { name: 'doorCode', value: stored.Part3.doorCode },
      { name: 'streetNo', value: stored.Part3.streetNo },
      { name: 'streetNoJob', value: stored.Part3.streetNoJob },
      { name: 'zipCode', value: stored.Part3.zipCode },
      { name: 'zipCodeJob', value: stored.Part3.zipCodeJob },
      { name: 'buildingNumber', value: stored.Part3.buildingNumber },
      { name: 'brfOrgnr', value: stored.Part3.brfOrgnr },
      { name: 'rotSsid', value: stored.Part2.ssid },
      { name: 'contact', value: stored.Part3.contact },
      { name: 'firstName', value: stored.Part3.firstName },
      { name: 'lastName', value: stored.Part3.lastName },
      { name: 'phoneNo', value: stored.Part3.phoneNo },
      { name: 'email', value: stored.Part3.email },
      { name: 'companyName', value: stored.Part3.companyName },
        // { name: 'rotSsid', value: stored.Part2.ssid},
      ].forEach(({ name, value }) => setValue(name, value))
    };

  }

  const onSubmit = async (formData) => {
    console.log(formData);
    window.dataLayer.push({event:'code', data: formData.code});

    setIsLoading(true);
    setGlobalState({ ...globalState, Part3: formData, isHouse, isOneAddress, otherJobAddress })
    setTimeout(() => {
      setIsLoading(false);
      goForward()
    }, 300);
  }

  const setAddress = (event) => {
    setTimeout(() => {
      let stored = globalState
      if (stored && stored.Part3) {
        [
          { name: 'addressJob', value: stored.Part3.addressJob },
          { name: 'cityJob', value: stored.Part3.cityJob },
          { name: 'doorCode', value: stored.Part3.doorCode },
          { name: 'streetNoJob', value: stored.Part3.streetNoJob },
          { name: 'zipCodeJob', value: stored.Part3.zipCodeJob },
          { name: 'contact', value: stored.Part3.contact }
        ].forEach(({ name, value }) => setValue(name, value))
      };
    }, 50);

    event.preventDefault();
    if (event.target.name === 'Ja') {
      setIsOneAddress(true)
      setOtherJobAddress(false)
      setGlobalState({ ...globalState, 'isOneAddress': true, 'otherJobAddress': false })
    } else if (event.target.name === 'Nej') {
      setIsOneAddress(false)
      setOtherJobAddress(true)
      setGlobalState({ ...globalState, 'isOneAddress': false, 'otherJobAddress': true })
    }
  };

  // save house or apartment data for rot-avdrag
  const setBuildingType = (event) => {
    setTimeout(() => {
      let stored = globalState
      if (stored && stored.Part3) {
        [
          { name: 'buildingNumber', value: stored.Part3.buildingNumber },
          { name: 'buildingNumberAp', value: stored.Part3.buildingNumberAp },
          { name: 'brfOrgnr', value: stored.Part3.brfOrgnr },
          { name: 'rotSsid', value: stored.Part2.ssid },
        ].forEach(({ name, value }) => setValue(name, value))
      };
    }, 50);

    event.preventDefault();
    if (event.target.name === 'Villa') {
      if (isHouse) {
        setIsHouse(false)
        setIsApartment(false)
        setGlobalState({ ...globalState, 'isHouse': false, 'isApartment': false })
      } else {
        setIsHouse(true)
        setIsApartment(false)
        setGlobalState({ ...globalState, 'isHouse': true, 'isApartment': false })
      }
    } else if (event.target.name === 'Bostadsrätt') {
      if (isApartment) {
        setIsHouse(false)
        setIsApartment(false)
        setGlobalState({ ...globalState, 'isHouse': false, 'isApartment': false })
      } else {
        setIsHouse(false)
        setIsApartment(true)
        setGlobalState({ ...globalState, 'isHouse': false, 'isApartment': true })
      }
    }
  };

  useEffect(() => {
    window.dataLayer.push({event:'conversionStep3'});
  }, [])

  useEffect(() => {

    getStoredData()
  }, [])



  return (
    <section>
      <div className="formcontainer">
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
              <HeadingSection
                heading={'Faktureringsuppgifter'}
              />
              <FlexContainer>
                <FlexChild>
                  {globalState && globalState.isCompany ? (<StyledH3>Förnamn på kontaktperson*</StyledH3>) : <StyledH3>Förnamn*</StyledH3>}

                  <StyledInput
                    type="text"
                    name="firstName"
                    ref={register({
                      required: "Ange förnamn",
                    })}
                  />
                  {errors.firstName ? <ErrorMessage>{errors.firstName.message}</ErrorMessage> : null}
                </FlexChild>
                <FlexChild>
                  {globalState && globalState.isCompany ? (<StyledH3>Efternamn på kontaktperson*</StyledH3>) : <StyledH3>Efternamn*</StyledH3>}

                  <StyledInput
                    type="text"
                    name="lastName"
                    ref={register({
                      required: "Ange efternamn",
                    })}
                  />
                  {errors.lastName ? <ErrorMessage>{errors.lastName.message}</ErrorMessage> : null}
                </FlexChild>
              </FlexContainer>
              <FlexContainer>
                <FlexChild>
                  <StyledH3>Adress*</StyledH3>
                  <StyledInput
                    type="text"
                    name="address"
                    ref={register({
                      required: "Ange adress",
                    })}
                  />
                  {errors.address ? <ErrorMessage>{errors.address.message}</ErrorMessage> : null}
                </FlexChild>
                <FlexChild>
                  <StyledH3>Gatunummer*</StyledH3>
                  <StyledInput
                    type="text"
                    name="streetNo"
                    ref={register({
                      required: "Ange gatunummer",
                    })}
                  />
                  {errors.streetNo ? <ErrorMessage>{errors.streetNo.message}</ErrorMessage> : null}
                </FlexChild>
              </FlexContainer>
              <FlexContainer>
                <FlexChild>
                  <StyledH3>Postnummer*</StyledH3>
                  <StyledInput
                    type="text"
                    name="zipCode"
                    ref={register({
                      required: "Ange postnummer",
                    })}
                  />
                  {errors.zipCode ? <ErrorMessage>{errors.zipCode.message}</ErrorMessage> : null}
                </FlexChild>
                <FlexChild>
                  <StyledH3>Postort*</StyledH3>
                  <StyledInput
                    type="text"
                    name="city"
                    ref={register({
                      required: "Ange postort",
                    })}
                  />
                  {errors.city ? <ErrorMessage>{errors.city.message}</ErrorMessage> : null}
                </FlexChild>
              </FlexContainer>
              <FlexContainer>
                <FlexChild>
                  <StyledH3>Telefonnummer*</StyledH3>
                  <StyledInput
                    type="text"
                    placeholder="+46123456789"
                    name="phoneNo"
                    ref={register({
                      required: "Ange telefonnummer",
                      pattern: {
                        value: /^\+46 *7[0123456789] *\d{4} *\d{3}$/,
                        message: "Ange telefonnummer enligt +46 XX XXXX XXX"
                      }
                    })}
                  />
                  {errors.phoneNo ? <ErrorMessage>{errors.phoneNo.message}</ErrorMessage> : null}
                </FlexChild>
                <FlexChild>
                  {/* <StyledH3>Email*</StyledH3>
                  <StyledInput
                    type="text"
                    placeholder="info@gmail.com"
                    name="email"
                    ref={register({
                      required: "Ange en mailadress",
                      pattern: {
                        value: /^\S+@\S+\.\S+$/,
                        message: "Ange en giltig email"
                      }
                    })}
                  />
                  {errors.email ? <ErrorMessage>{errors.email.message}</ErrorMessage> : null} */}
                </FlexChild>
              </FlexContainer>
              <FlexContainer>
                <FlexChild>
                  <StyledH3>Partnerkod</StyledH3>
                  <StyledInput
                    type="text"
                    name="code"
                    ref={register({
                      maxLength: {
                        value: 30,
                        message: "Ogiltlig kod",
                      },
                    })}
                  />
                </FlexChild>
                <FlexChild></FlexChild>
              </FlexContainer>
            </FormContent>
            <FormContent>
              <StyledH3>ÄR ARBETSPLATSADRESSEN SAMMA SOM FAKTURAADRESSEN OVAN?</StyledH3>
              <ButtonGroup
                buttons={["Ja", "Nej"]}
                onClickAction={setAddress}
                preClickedId={otherJobAddress ? 1 : 0}
              />
            </FormContent>

            {isOneAddress && (
              <>
                <FormContent>
                  <FlexContainer>
                    <FlexChildOne>
                      <StyledH3>Portkod?</StyledH3>
                      <StyledInput
                        placeholder="Ange eventuell portkod"
                        type="text"
                        name="doorCode"
                        ref={register}
                      />
                    </FlexChildOne>
                  </FlexContainer>
                </FormContent>
                {isLoading ?
                  <CircleLoader />
                  :
                  <ActionBtnContainer>
                    <ActionBtnLeft onClick={() => goBack()}>Tillbaka</ActionBtnLeft>
                    <ActionBtnRight type="submit">Gå vidare</ActionBtnRight>
                  </ActionBtnContainer>
                }
              </>
            )}

            {otherJobAddress && (
              <FormContent>
                <H2Margin>Arbetsplatsadress</H2Margin>
                <FlexContainer>
                  <FlexChild>
                    <StyledH3>Adress</StyledH3>
                    <StyledInput
                      type="text"
                      name="addressJob"
                      ref={register({ required: "Ange Adress" })}
                    />
                  </FlexChild>
                  <FlexChild>
                    <StyledH3>Gatunummer</StyledH3>
                    <StyledInput
                      type="text"
                      name="streetNoJob"
                      ref={register}
                    />
                  </FlexChild>
                </FlexContainer>

                <FlexContainer>
                  <FlexChild>
                    <StyledH3>Postnummer</StyledH3>
                    <StyledInput
                      type="text"
                      name="zipCodeJob"
                      ref={register}
                    />
                  </FlexChild>
                  <FlexChild>
                    <StyledH3>Postort</StyledH3>
                    <StyledInput
                      type="text"
                      name="cityJob"
                      ref={register}
                    />
                  </FlexChild>
                </FlexContainer>
                <FlexContainer>
                  <FlexChildOne>
                    <StyledH3>Portkod?</StyledH3>
                    <StyledInput
                      placeholder="Ange eventuell portkod"
                      type="text"
                      name="doorCode"
                      ref={register}
                    />
                  </FlexChildOne>
                </FlexContainer>
              </FormContent>

            )}
            {otherJobAddress && (
              <>
                {isLoading ?
                  <CircleLoader />
                  :
                  <ActionBtnContainer>
                    <ActionBtnLeft onClick={() => goBack()}>Tillbaka</ActionBtnLeft>
                    <ActionBtnRight type="submit">Gå vidare</ActionBtnRight>
                  </ActionBtnContainer>
                }
              </>
            )}
          </form>

        </Card>
      </div>
    </section>
  );
};
export default Part3;
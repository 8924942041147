import React, { useState, useEffect } from 'react';
import axios from 'axios'
import CircleLoader from '../CircleLoader';
import Card from '../Card';
import ErrorComponent from '../ErrorComponent';
import HeadingSection from '../HeadingSection';
import { 
  FormContent,
  ConfirmationWrapper } from '../../styles/global';
import { createGlobalStyle } from 'styled-components';

const url = require('./config.json')

const Part7 = ({ globalState }) => {
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false)
  
  const setTrackingCookie = (orderID) => {
    const code = globalState.Part3.code
    const a = document;
    const d = 'script';
    const e = a.createElement(d);
    e.innerText = `!(function (a, d, r, e, c) { adrecord = {"programID": 1325, "orderID": ${orderID}, "couponCode": "${code}"}; e = a.createElement(d); e.async=!0; e.src=r; c=a.getElementsByTagName(d)[0]; c.parentNode.insertBefore(e, c);})(document,"script","https://t.adii.io/convert.js");`
    const c = a.getElementsByTagName(d)[0]
    c.parentNode.insertBefore(e, c);
  }

  const formatSsid = () => {

    // The app never shows rot alternative if ssid is null
    if(globalState.isCompany){
      return null
    }
    else{  
      // Return ssid as rotssid
      return globalState.Part2.ssid
    }
  }

  useEffect(() => {
    window.dataLayer.push({event:'conversionStep5'});
  }, [])

  const postOrder = async () => {
    const apiClient = axios.create({
      baseURL: url.baseURL,
      responseType: 'json',
    })
    const payload = {
      profession:{ 
        id: globalState.Part1.profession
      },
      customer: {
        firstName: globalState.Part3.firstName,
        lastName: globalState.Part3.lastName,
        address: globalState.Part3.address,
        streetNo: globalState.Part3.streetNo,
        zipCode: globalState.Part3.zipCode,
        city: globalState.Part3.city,
        ssid: globalState.Part2.ssid,
        phone: globalState.Part3.phoneNo,
        email: globalState.Part3.email,
        isCompany: globalState.isCompany,
        companyName: (globalState.Part3.companyName ? globalState.Part3.companyName : ''),
      },
      order: {
        title: globalState.Part1.title,
        description: globalState.Part1.description,
        time: new Date(),
        phone: globalState.Part3.phoneNo,
        email: globalState.Part3.email,
        firstName: globalState.Part3.firstName,
        lastName: globalState.Part3.lastName,
        profession: globalState.Part1.profession,
        area: JSON.stringify(globalState.area.id),
        address: (globalState.Part3.addressJob ? globalState.Part3.addressJob : globalState.Part3.address),
        streetNo: (globalState.Part3.streetNoJob ? globalState.Part3.streetNoJob : globalState.Part3.streetNo),
        zipCode: (globalState.Part3.zipCodeJob ? globalState.Part3.zipCodeJob : globalState.Part3.zipCode),
        city: (globalState.Part3.cityJob ? globalState.Part3.cityJob : globalState.Part3.city),
        doorCode: (globalState.Part3.doorCode === undefined || globalState.Part3.doorCode === "") ? undefined : globalState.Part3.doorCode,
        buildingNumber: (globalState.Part3.buildingNumber === undefined || globalState.Part3.buildingNumber === "") ? undefined : globalState.Part3.buildingNumber,
        buildingNumberAp: (globalState.Part3.buildingNumberAp === undefined || globalState.Part3.buildingNumberAp === "") ? undefined : globalState.Part3.buildingNumberAp,
        brfOrgnr: (globalState.Part3.brfOrgnr === undefined || globalState.Part3.brfOrgnr === "") ? undefined : globalState.Part3.brfOrgnr,
        rotSsid: formatSsid(),
        isHouse: globalState.isHouse,
        isApartment: globalState.isApartment,
        code: globalState.Part3.code
      }
    }

    try {
      const response = await apiClient.post(
        '/orders', payload
      )

      if (response.status === 200) {
        window.dataLayer.push({event:'conversion'});
        setIsLoading(false);
        setShowConfirm(true)
        setTrackingCookie(response.data.orderID);
        localStorage.clear();
      } else {
        setServerError('Någonting gick fel.')
        setIsLoading(false);
      }
    } catch(error) {
      setServerError('Någonting gick fel.')
      setIsLoading(false);
    }
  }

  useEffect(() => {
    postOrder()
  }, [])

  return (
    <section>
      <Card>
      {!showConfirm && !serverError && <CircleLoader />}
        {showConfirm && !serverError && (
          <FormContent>
            <ConfirmationWrapper >
              <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MT6BDS6" style={{height: 0, width: 0, display: 'none', visibility: 'hidden'}} />
              <HeadingSection 
                heading={'Tack för att du anlitar 24 Duty'}
                paragraph={`Du kommer nu få löpande uppdateringar på sms angående din order.`}
                paragraph2={`Just nu söker vi efter en hantverkare. Din beräknade väntetid är 1 minut. `}
                paragraph3={`Din beräknade ankomsttid av hantverkare är 60-90 minuter.`}
              />
            </ConfirmationWrapper>
          </FormContent>
        )}

        {serverError && (
          <ErrorComponent isLastPart={true} serverError={serverError} />
        )}
      </Card>
    </section>
  )
};
export default Part7;

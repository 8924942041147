import React from 'react';
import { StyledH2, StyledParagraph, StyledHeader } from '../styles/typography';
import { StyledCenterDivColumn } from '../styles/global';
import logo from "../assets/icons/logo.png";
import styled from 'styled-components';

const ImageWrapper = styled.div`
  padding-top: 30px;
`;

const HeadingSection = ({ heading, paragraph, paragraph2, paragraph3 }) => {
  return (
    <StyledHeader>
      <StyledCenterDivColumn>
      <StyledH2>{heading}</StyledH2>
      {paragraph && (
        <StyledParagraph>{paragraph}</StyledParagraph>
      )}
      {paragraph2 && (
        <StyledParagraph>{paragraph2}</StyledParagraph>
      )}
      {paragraph3 && (
        <StyledParagraph>{paragraph3}</StyledParagraph>
      )}
      </StyledCenterDivColumn>
    
      <ImageWrapper>
        <img src={logo} width="50" height="50"/>
      </ImageWrapper>
    </StyledHeader>
  )
}
  
export default HeadingSection
import React, { useState } from 'react';
import Part1 from "./components/steps/Part1";
import Part2 from "./components/steps/Part2";
import Part3 from "./components/steps/Part3";
import Part5 from "./components/steps/Part5";
import Part7 from "./components/steps/Part7";
import ProgressBar from "./components/ProgressBar";
import { Wrapper } from './styles/global';
import { Routes, Route, Link } from "react-router-dom";

const useFormProgress = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const goForward = () => {
    setCurrentStep(currentStep + 1);
  }
  const goBack = () => {
    setCurrentStep(currentStep - 1);
  }
  return [currentStep, goForward, goBack, setCurrentStep];
}

const App = () => {
  
  const [globalState, setGlobalState] = useState();
  const [currentStep, goForward, goBack, setCurrentStep] = useFormProgress();
  const steps = [
    <Part1 globalState={globalState} setGlobalState={setGlobalState} goForward={goForward} />,
    <Part2 globalState={globalState} setGlobalState={setGlobalState} goForward={goForward} goBack={goBack}/>,
    <Part3 globalState={globalState} setGlobalState={setGlobalState} goForward={goForward} goBack={goBack} />,
    <Part5 globalState={globalState} setGlobalState={setGlobalState} goForward={goForward} goBack={goBack} setCurrentStep={setCurrentStep} />,
    <Part7 globalState={globalState} />,
  ];

  return (
    
    <Wrapper>
      
      <Routes>
        <Route path="/" element={<>
          <ProgressBar currentStep={currentStep} />
            {steps[currentStep]}
          </>} />
        <Route path="/tack" element={<>
          <ProgressBar currentStep={4} />
          <Part7 globalState={globalState} />
        </>} />
      </Routes>
    </Wrapper>
  )};

export default App;
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form';
import CircleLoader from '../CircleLoader';
import ErrorComponent from '../ErrorComponent';
import HeadingSection from '../HeadingSection';
import Card from '../Card';
import PolicyModal from '../PolicyModal';
import timer from "../../assets/icons/timer.png";
import clock from "../../assets/icons/clock.png";
import { StyledH3, StyledH2, StyledParagraph } from '../../styles/typography';
import {
  StyledInput,
  ErrorMessage,
  StyledSelect,
  StyledTextarea,
  StyledCheckbox,
  StyledCenterDevRight,
  StyledImg,
  ErrorMessageCheckbox,
  FormContent,
  ActionBtnRight,
  ActionBtnLeft,
  ActionBtnContainer,
  FlexContainerInline,
  FlexContainer,
  FlexChild,
  StyledCenterDiv,
  StyledSpacedDiv,
  ModalLink
} from '../../styles/global';
import { storeInLocalStorage } from '../../functions'

const url = require('./config.json')

const Part1 = ({ setGlobalState, goForward, globalState }) => {
  const { register, handleSubmit, setValue, formState: { isDirty, errors } } = useForm({});
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [professions, setProfessions] = useState([])
  const [subCategory, setSubCategory] = useState([]);
  const [open, setOpen] = useState(false)

  const getStoredData = () => {
    let stored = localStorage.getItem('stored');
    if (!stored || stored.Part1) {
      return null
    }
    let parsed = JSON.parse(stored)
    if (parsed) {
      [{ name: 'title', value: parsed.Part1.title },
      { name: 'description', value: parsed.Part1.description },
      { name: 'profession', value: parsed.Part1.profession },
      { name: 'zipCode', value: parsed.Part1.zipCode },
      ].forEach(({ name, value }) => setValue(name, value))
    };
  }



  const onSubmit = async (Part1) => {
    setIsLoading(true);
    const apiClient = axios.create({
      baseURL: url.baseURL,
      responseType: 'json',
    })
    try {
      let code = Part1.zipCode.replace(/\s+/g, '')
      
      window.dataLayer.push({event:'zip', data: code.slice(0,3)});
      const response = await apiClient.get(
        `/areas/zipCode/${code}`,
      )
      const data = response
      if (data.data.length === 0) {
        setIsLoading(false);
        setServerError('Tyvärr finns vi ännu inte i ditt område.')
      }
      let area = {
        id: data.data[0].id,
        area: data.data[0].name
      }
      setIsLoading(false);
      setGlobalState({ Part1, area, professions })
      window.dataLayer.push({event: "profession", data: Part1.profession })
      goForward()
    } catch (error) {
      console.log("error", error)
      setServerError('Tyvärr finns vi ännu inte i ditt område.')
      setIsLoading(false);
    }
  }


  const getCategories = async () => {
    const apiClient = axios.create({
      baseURL: url.baseURL,
      responseType: 'json',
    })
    try {
      const response = await apiClient.get('/professions')
      const data = response
      if (data.status === 200) {
        setProfessions(response.data)
      }
    } catch (error) {
      if (error.response.status === 404) {
        setServerError('Kan inte ansluta till servern')
      } else {
        setServerError('Tyvärr finns vi ännu inte i ditt område.')
      }
    }
  }


  useEffect(() => {
    window.dataLayer.push({event:'conversionStep1'});

    getCategories()
    
    if (!isDirty) {
      getStoredData()
    }
  
  }, [])

  return (
    <>
      <section>
        <Card>
          {serverError ?
            <ErrorComponent serverError={serverError} />
            :
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormContent>
                <HeadingSection
                  heading={'Vad behöver du hjälp med?'}
                />
                <StyledSpacedDiv>
                  <StyledCenterDiv>
                    <StyledCenterDiv>
                    <StyledImg src={timer} width="20" height="20"/>
                      <StyledParagraph>Svar inom 1 min</StyledParagraph>
                      </StyledCenterDiv>
                    <StyledCenterDevRight>
                    <StyledImg src={clock} width="20" height="20"/>
                    <StyledParagraph>Hantverkare på plats inom 60-90 min</StyledParagraph>
                    </StyledCenterDevRight>
                  </StyledCenterDiv>
                </StyledSpacedDiv>
                <StyledH3>Vad har hänt?*</StyledH3>
                <StyledInput
                  type="text"
                  name="title"
                  ref={register({
                    required: "Ange titel",
                    maxLength: {
                      value: 30,
                      message: "Max 30 tecken"
                    }
                  })}
                />
                {errors.title ? <ErrorMessage>{errors.title.message}</ErrorMessage> : null}

                <StyledH3>Beskrivning?*</StyledH3>
                <StyledTextarea
                  name="description"
                  ref={register({
                    required: "Beskriv ditt ärende"
                  })}
                />
                {errors.description ? <ErrorMessage>{errors.description.message}</ErrorMessage> : null}

                <FlexContainer>
                  <FlexChild>
                    <StyledH3>Yrke*</StyledH3>
                    <StyledSelect
                      name="profession"
                      ref={register({
                        required: "Välj yrke"
                      })}
                    >
                      <option value="">Välj</option>
                      {professions && professions.map((profession) => {
                        return (
                          <option key={profession.id} value={profession.id}>{profession.title}</option>
                        )
                      })}
                    </StyledSelect>
                    {errors.profession ? <ErrorMessage>{errors.profession.message}</ErrorMessage> : null}
                  </FlexChild>


                  <FlexChild>
                    <StyledH3>Postnummer för arbetet*</StyledH3>
                    <StyledInput
                      type="text"
                      name="zipCode"
                      ref={register({
                        required: "Ange postnummer",
                        pattern: {
                          value: /^(?=(\D*\d){5}\D*$)(?=[^ ]* ?[^ ]*$)[\d ]*$/,
                          message: "Ange korrekt postnummer"
                        },
                      })}
                    />
                    {errors.zipCode ? <ErrorMessage>{errors.zipCode.message}</ErrorMessage> : null}
                  </FlexChild>
                </FlexContainer>
                <FlexContainerInline>
                  <StyledCheckbox type="checkbox" placeholder="privacyPolicy" name="privacyPolicy" ref={register({ required: 'Godkänn för att gå vidare' })} />
                  <StyledH3>Jag godkänner 24dutys <ModalLink onClick={() => setOpen(!open)}>Privacy Policy</ModalLink>*</StyledH3>
                  {errors.privacyPolicy ? <ErrorMessageCheckbox>{errors.privacyPolicy.message}</ErrorMessageCheckbox> : null}
                </FlexContainerInline>
              </FormContent>

              {isLoading ?
                <CircleLoader />
                :
                <ActionBtnContainer>
                  <ActionBtnLeft disabled>&nbsp;</ActionBtnLeft>
                  <ActionBtnRight type="submit">Gå vidare</ActionBtnRight>
                </ActionBtnContainer>
              }

            </form>
          }
        </Card>
      </section>

      {open && (
        <PolicyModal open={open} setOpen={setOpen} />
      )}

    </>
  );
};
export default Part1;
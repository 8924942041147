import React from 'react';
import ButtonGroup from './ButtonGroup'
import { StyledH2, StyledParagraph } from '../styles/typography';
import { FormContent } from '../styles/global';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ButtonWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.space[3]};
`
const ErrorComponent = ({serverError, isLastPart}) => {
  const navigate = useNavigate();

  const reloadPage = () => {
    if(isLastPart){
      navigate("/")
      window.location.reload();

    } else {
      window.location.reload();
    }
    
  }

  return (
    <FormContent>
      <StyledH2>Ojdå ...</StyledH2>
      <StyledParagraph>{serverError}</StyledParagraph>
      <ButtonWrapper>
        <ButtonGroup 
          buttons={["Tillbaka"]}
          onClickAction={reloadPage}
        />
      </ButtonWrapper>
    </FormContent>
  )
}
  
export default ErrorComponent
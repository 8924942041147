import styled from 'styled-components';

export const StyledH2 = styled.h2`
  padding-top: ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[6]};
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  line-height: 1.3;
`;
export const StyledH3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  margin: 0;
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.space[3]};
`;

export const StyledParagraph = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: 1.5;
  margin: 0;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center,
  padding-bottom: 20px;
`;


import React from 'react';
import styled from 'styled-components';
import ProgressStage from './ProgressStage';

const Wrapper = styled.section`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const ProgressStageWrapper = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  margin-left: ${({ theme }) => theme.space[1]};
  ${({ theme }) => theme.sm`
     margin-left: ${({ theme }) => theme.space[0]};
  `}
  justify-content: flex-start;
  list-style-type: none;
`;

const ProgressBar = ({ currentStep }) => {
  return (
    <Wrapper>
      <ProgressStageWrapper>
        <ProgressStage
          stage={currentStep === 0 ? "active" : currentStep > 0 ? "complete" : ""}
          step={1}
        />
        <ProgressStage
          stage={currentStep === 1 ? "active" : currentStep > 1 ? "complete" : ""}
          step={2}
        />
        <ProgressStage
          stage={currentStep === 2 ? "active" : currentStep > 2 ? "complete" : ""}
          step={3}
        />
        <ProgressStage
          stage={currentStep === 3 ? "active" : currentStep > 3 ? "complete" : ""}
          step={4}
        />
        <ProgressStage
          stage={currentStep === 4 ? "active" : currentStep > 4 ? "complete" : ""}
          step={5}
        />
        
        </ProgressStageWrapper>
    </Wrapper>
  );
};

export default ProgressBar;
import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  background: ${({ theme }) => theme.primary};
  border-radius: ${({ theme }) => theme.borderRadius.form};
  margin: 0 10px;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.space[1]};
  height: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 2px rgba(19, 63, 63, 0.25);
  ${({ theme }) => theme.sm`
     margin: 0 auto;
  `}
`;

const Card = ({ children }) => {
  return (
    <Container>
        {children}
    </Container>  
  )
};
export default Card;